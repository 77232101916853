export default {
  404: '/404',
  accountants: '/accountants',
  accountingTerms: '/accounting-terms',
  automatedInsights: '/automated-insights',
  balanceSheetTemplate: '/balance-sheet-template',
  blog: '/blog',
  careers: '/careers',
  deleteAccount: '/delete-account',
  emailPreferences: '/email-preferences',
  fbRenew: '/fb-renew',
  freeTrial: '/free-trial',
  index: '/',
  incomeStatementTemplate: '/income-statement-template',
  integrateSeamlesslyWithQuickbooks: '/integrate-seamlessly-with-quickbooks',
  integrations: '/integrations',
  integrationSupport: '/integration-support',
  invoiceTemplate: '/invoice-template',
  neatFeatures: '/neat-features',
  neatHome: '/neathome',
  neatvault: '/neatvault',
  offerNeatvault: '/offer-neatvault',
  ourHistory: '/our-history',
  policies: '/policies',
  pricing: '/pricing',
  privacy: '/policies/privacy',
  productReleases: '/product-releases',
  receiptDocumentScanners: '/receipt-document-scanners',
  referral: '/referral',
  referralTermsConditions: '/referral-terms-conditions',
  renew: '/renew',
  policiesSalesTax: '/policies/sales-tax',
  security: '/security',
  signUp: '/t',
  signUpSuccess: '/t/success',
  taxPrep: '/tax-prep',
  termsConditions: '/terms-conditions',
  trackReceiptsTrial: '/track-receipts-trial',
  trackReceipts: '/track-receipts',
  neatVsExpensify: '/neat-vs-expensify',
  neatVsAutoentry: '/neat-vs-autoentry',
  neatHomeVsExpensify: '/neathome-vs-expensify',
  neatHomeVsMint: '/neathome-vs-mint',
  transactionStreaming: '/transaction-streaming',
  vipService: '/vip-service',
  webinars: '/webinars',
  sweetDeal: '/sweet-deal',
  scansnapCloud: '/scansnap-cloud',
}
