import { APP_URL } from '@/config'

export default {
  default: {
    twitter: {
      cardType: 'summary_large_image',
      site: '@neatcompany',
      handle: '@neatcompany',
    },
    facebook: {
      appId: '213623165805968',
    },
    openGraph: {
      locale: 'en_US',
      site_name: 'The Neat Company',
    },
    jsonLD: {
      organization: {
        id: `${APP_URL}/#organization`,
        name: 'The Neat Company',
        url: APP_URL,
        sameAs: [
          'https://www.facebook.com/NeatCompany/',
          'https://www.linkedin.com/company/the-neat-company_2/',
        ],
        logo: {
          '@type': 'ImageObject',
          '@id': `${APP_URL}/#logo`,
          inLanguage: 'en-US',
          url: '',
          contentUrl: '',
          caption: 'The Neat Company',
        },
        image: { '@id': `${APP_URL}/#logo` },
      },
      website: {
        id: `${APP_URL}/#website`,
        url: APP_URL,
        name: 'The Neat Company',
        description: '',
        publisher: { '@id': `${APP_URL}/#organization` },
        potentialAction: [{ '@type': 'SearchAction', target: { '@type': 'EntryPoint', urlTemplate: `${APP_URL}/?s={search_term_string}` }, 'query-input': 'required name=search_term_string' }],
        inLanguage: 'en-US',
      },
    },
  },
  404: { seo: {} },
  neatFeatures: {
    title: 'Features | Neat | Smarter receipt and document management',
    description: 'Neat\'s invoice and receipt tracker helps businesses store and organize financial documents with their scanner or mobile phone. Try free for 15 days.',
    canonical: `${APP_URL}/neat-features/`,
    twitter: {
      cardType: 'summary_large_image',
    },
    openGraph: {
      url: `${APP_URL}/neat-features/`,
      type: 'article',
      article: {
        modifiedTime: '2023-06-14T00:51:25+00:00',
      },
    },
    jsonLD: {
      webPage: {
        id: `${APP_URL}/neat-features/#webpage`,
        url: `${APP_URL}/neat-features/`,
        name: 'Features | Neat | Smarter receipt and document management',
        isPartOf: { '@id': `${APP_URL}/#website` },
        datePublished: '2023-06-14T00:51:25+00:00',
        dateModified: '2023-06-14T00:51:25+00:00',
        description: 'Explore Neat\'s features and capabilities. Manage and organize your home or business. Unlimited file and document storage, expense tracking, bank-level encryption, and more!',
        breadcrumb: { '@id': `${APP_URL}/neat-features/#breadcrumb` },
        inLanguage: 'en-US',
        potentialAction: [{ '@type': 'ReadAction', target: [`${APP_URL}/neat-features/`] }],
        primaryImageOfPage: { '@id': 'https://www.neat.com/neat-features/#primaryimage' },
      },
      breadCrumb: {
        itemListElements: [
          { position: 1, name: 'Home', item: APP_URL },
          { position: 2, name: 'Neat Features Potential' },
        ],
      },
    },
    uri: '/neat-features/',
    additionalMetaTags: [
      {
        property: 'article:publisher',
        content: 'https://www.facebook.com/NeatCompany/',
      },
      {
        name: 'twitter:label1',
        content: 'Est. reading time',
      },
      {
        name: 'twitter:data1',
        content: '4 minutes',
      },
    ],
  },
  neatHome: {
    title: 'NeatHome',
    description: '',
    canonical: `${APP_URL}/neathome/`,
    twitter: {
      cardType: 'summary_large_image',
    },
    openGraph: {
      url: `${APP_URL}/neathome/`,
      type: 'article',
      article: {
        modifiedTime: '2023-06-14T00:51:25+00:00',
      },
    },
    jsonLD: {
      webPage: {
        id: `${APP_URL}/neathome/#webpage`,
        url: `${APP_URL}/neathome/`,
        name: 'NeatHome',
        isPartOf: { '@id': `${APP_URL}/#website` },
        datePublished: '2024-03-16T00:51:25+00:00',
        dateModified: '2024-03-16T00:51:25+00:00',
        description: '',
        breadcrumb: { '@id': `${APP_URL}/neathome/#breadcrumb` },
        inLanguage: 'en-US',
        potentialAction: [{ '@type': 'ReadAction', target: [`${APP_URL}/neathome/`] }],
        primaryImageOfPage: { '@id': 'https://www.neat.com/neathome/#primaryimage' },
      },
      breadCrumb: {
        itemListElements: [
          { position: 1, name: 'Home', item: APP_URL },
          { position: 2, name: 'Neat Home' },
        ],
      },
    },
    uri: '/neathome/',
    additionalMetaTags: [
      {
        property: 'article:publisher',
        content: 'https://www.facebook.com/NeatCompany/',
      },
      {
        name: 'twitter:label1',
        content: 'Est. reading time',
      },
      {
        name: 'twitter:data1',
        content: '4 minutes',
      },
    ],
  },
  neatVsAutoentry: {
    title: 'Compare Neat Vs AutoEntry',
    description: 'Learn about the comparisons of two apps, Neat & AutoEntry, that both can scan and track receipts, invoices & bank statements.',
    canonical: `${APP_URL}/neat-vs-autoentry/`,
    twitter: {
      cardType: 'summary_large_image',
    },
    openGraph: {
      url: `${APP_URL}/neat-vs-autoentry/`,
      type: 'article',
      article: {
        modifiedTime: '2023-06-14T00:51:25+00:00',
      },
    },
    jsonLD: {
      webPage: {
        id: `${APP_URL}/neat-vs-autoentry/#webpage`,
        url: `${APP_URL}/neat-vs-autoentry/`,
        name: 'Compare Neat Vs AutoEntry',
        isPartOf: { '@id': `${APP_URL}/#website` },
        datePublished: '2024-08-18T00:51:25+00:00',
        dateModified: '2024-08-18T00:51:25+00:00',
        description: 'Learn about the comparisons of two apps, Neat & AutoEntry, that both can scan and track receipts, invoices & bank statements.',
        breadcrumb: { '@id': `${APP_URL}/neat-vs-autoentry/#breadcrumb` },
        inLanguage: 'en-US',
        potentialAction: [{ '@type': 'ReadAction', target: [`${APP_URL}/neat-vs-autoentry/`] }],
        primaryImageOfPage: { '@id': 'https://www.neat.com/neat-vs-autoentry/#primaryimage' },
      },
      breadCrumb: {
        itemListElements: [
          { position: 1, name: 'Home', item: APP_URL },
          { position: 2, name: 'Neat Vs AutoEntry' },
        ],
      },
    },
    uri: '/neat-vs-autoentry/',
    additionalMetaTags: [
      {
        property: 'article:publisher',
        content: 'https://www.facebook.com/NeatCompany/',
      },
      {
        name: 'twitter:label1',
        content: 'Est. reading time',
      },
      {
        name: 'twitter:data1',
        content: '4 minutes',
      },
    ],
  },
  scansnapCloud: {
    title: 'Scansnap Cloud',
    description: '',
    canonical: `${APP_URL}/scansnap-cloud/`,
    twitter: {
      cardType: 'summary_large_image',
    },
    openGraph: {
      url: `${APP_URL}/scansnap-cloud/`,
      type: 'article',
      article: {
        modifiedTime: '2023-06-14T00:51:25+00:00',
      },
    },
    jsonLD: {
      webPage: {
        id: `${APP_URL}/scansnap-cloud/#webpage`,
        url: `${APP_URL}/scansnap-cloud/`,
        name: 'Scansnap Cloud',
        isPartOf: { '@id': `${APP_URL}/#website` },
        datePublished: '2024-03-16T00:51:25+00:00',
        dateModified: '2024-03-16T00:51:25+00:00',
        description: '',
        breadcrumb: { '@id': `${APP_URL}/scansnap-cloud/#breadcrumb` },
        inLanguage: 'en-US',
        potentialAction: [{ '@type': 'ReadAction', target: [`${APP_URL}/scansnap-cloud/`] }],
        primaryImageOfPage: { '@id': 'https://www.neat.com/scansnap-cloud/#primaryimage' },
      },
      breadCrumb: {
        itemListElements: [
          { position: 1, name: 'Home', item: APP_URL },
          { position: 2, name: 'Scansnap Cloud' },
        ],
      },
    },
    uri: '/scansnap-cloud/',
    additionalMetaTags: [
      {
        property: 'article:publisher',
        content: 'https://www.facebook.com/NeatCompany/',
      },
      {
        name: 'twitter:label1',
        content: 'Est. reading time',
      },
      {
        name: 'twitter:data1',
        content: '4 minutes',
      },
    ],
  },
  accountants: {
    title: 'Automated Bookkeeping Software For Accountants | Neat',
    description: 'Eliminated manual data entry and manage your clients receipts, invoices, and tax documents in one easy-to-use, paperless platform. Get a free demo.',
    canonical: `${APP_URL}/accountants/`,
    twitter: {
      cardType: 'summary_large_image',
    },
    openGraph: {
      url: `${APP_URL}/accountants/`,
      type: 'article',
      article: {
        modifiedTime: '2023-04-23T16:34:01+00:00',
      },
    },
    jsonLD: {
      webPage: {
        id: `${APP_URL}/accountants/#webpage`,
        url: `${APP_URL}/accountants/`,
        name: 'Automated Bookkeeping Software For Accountants | Neat',
        isPartOf: { '@id': `${APP_URL}/#website` },
        datePublished: '2019-05-04T11:30:50+00:00',
        dateModified: '2023-04-23T16:34:01+00:00',
        description: 'Eliminated manual data entry and manage your clients receipts, invoices, and tax documents in one easy-to-use, paperless platform. Get a free demo.',
        breadcrumb: { '@id': `${APP_URL}/accountants/#breadcrumb` },
        inLanguage: 'en-US',
        potentialAction: [{ '@type': 'ReadAction', target: [`${APP_URL}/accountants/`] }],
        primaryImageOfPage: { '@id': 'https://www.neat.com/accountants/#primaryimage' },
      },
      breadCrumb: {
        itemListElements: [
          { position: 1, name: 'Home', item: APP_URL },
          { position: 2, name: 'Accountants' },
        ],
      },
    },
    uri: '/accountants/',
    additionalMetaTags: [
      {
        property: 'article:publisher',
        content: 'https://www.facebook.com/NeatCompany/',
      },
      {
        name: 'twitter:label1',
        content: 'Est. reading time',
      },
      {
        name: 'twitter:data1',
        content: '27 minutes',
      },
    ],
  },
  accountingTerms: {
    title: 'Automated Bookkeeping Software For Accountants | Neat',
    description: 'We all know that jargon blocks your ability to understand information. (And everybusiness owner who\'s worked with an accountant said, "Amen!")',
    canonical: `${APP_URL}/accounting-terms/`,
    twitter: {
      cardType: 'summary_large_image',
    },
    openGraph: {
      url: `${APP_URL}/accounting-terms/`,
      type: 'article',
      article: {
        modifiedTime: '2022-05-11T19:56:46+00:00',
      },
    },
    jsonLD: {
      webPage: {
        id: `${APP_URL}/accounting-terms/#webpage`,
        url: `${APP_URL}/accounting-terms/`,
        name: '56 Accounting Terms Defined | Neat',
        isPartOf: { '@id': `${APP_URL}/#website` },
        datePublished: '2021-07-15T18:28:23+00:00',
        dateModified: '2022-05-11T19:56:46+00:00',
        description: 'We all know that jargon blocks your ability to understand information. (And everybusiness owner who\'s worked with an accountant said, "Amen!")',
        breadcrumb: { '@id': `${APP_URL}/accounting-terms/#breadcrumb` },
        inLanguage: 'en-US',
        potentialAction: [{ '@type': 'ReadAction', target: [`${APP_URL}/accounting-terms/`] }],
        primaryImageOfPage: { '@id': 'https://www.neat.com/accounting-terms/#primaryimage' },
      },
      breadCrumb: {
        itemListElements: [
          { position: 1, name: 'Home', item: APP_URL },
          { position: 2, name: 'Accounting Terms' },
        ],
      },
    },
    uri: '/accounting-terms/',
    additionalMetaTags: [
      {
        property: 'article:publisher',
        content: 'https://www.facebook.com/NeatCompany/',
      },
      {
        name: 'twitter:label1',
        content: 'Est. reading time',
      },
      {
        name: 'twitter:data1',
        content: '32 minutes',
      },
    ],
  },
  automatedInsights: {
    title: 'The Neat Company: Small business finances made easy',
    description: 'Simplify bookkeeping with Neat\'s mobile accounting software & storage. Automate reports, gain business insights, manage receipts & expenses. Try for free.',
    canonical: `${APP_URL}/automated-insights/`,
    twitter: {
      cardType: 'summary_large_image',
    },
    openGraph: {
      url: `${APP_URL}/automated-insights/`,
      type: 'article',
      article: {
        modifiedTime: '2023-02-03T20:32:47+00:00',
      },
    },
    jsonLD: {
      webPage: {
        id: `${APP_URL}/automated-insights/#webpage`,
        url: `${APP_URL}/automated-insights/`,
        name: 'The Neat Company: Small business finances made easy',
        isPartOf: { '@id': `${APP_URL}/#website` },
        datePublished: '2022-10-21T18:10:23+00:00',
        dateModified: '2023-02-03T20:32:47+00:00',
        description: 'Simplify bookkeeping with Neat\'s mobile accounting software & storage. Automate reports, gain business insights, manage receipts & expenses. Try for free.',
        breadcrumb: { '@id': `${APP_URL}/automated-insights/#breadcrumb` },
        inLanguage: 'en-US',
        potentialAction: [{ '@type': 'ReadAction', target: [`${APP_URL}/automated-insights/`] }],
        primaryImageOfPage: { '@id': 'https://www.neat.com/automated-insights/#primaryimage' },
      },
      breadCrumb: {
        itemListElements: [
          { position: 1, name: 'Home', item: APP_URL },
          { position: 2, name: 'Automated Insights' },
        ],
      },
    },
    uri: '/automated-insights/',
    additionalMetaTags: [
      {
        property: 'article:publisher',
        content: 'https://www.facebook.com/NeatCompany/',
      },
      {
        name: 'twitter:label1',
        content: 'Est. reading time',
      },
      {
        name: 'twitter:data1',
        content: '11 minutes',
      },
    ],
  },
  balanceSheetTemplate: {
    title: 'Balance Sheet Template | The Neat Company',
    description: 'We\'ve built this free balance sheet template to help you manage your numbers quickly and easily.',
    canonical: `${APP_URL}/balance-sheet-template/`,
    twitter: {
      cardType: 'summary_large_image',
    },
    openGraph: {
      url: `${APP_URL}/balance-sheet-template/`,
      type: 'article',
      article: {
        modifiedTime: '2023-02-03T20:45:22+00:00',
      },
    },
    jsonLD: {
      webPage: {
        id: `${APP_URL}/balance-sheet-template/#webpage`,
        url: `${APP_URL}/balance-sheet-template/`,
        name: 'Balance Sheet Template | The Neat Company',
        isPartOf: { '@id': `${APP_URL}/#website` },
        datePublished: '2022-10-21T18:10:23+00:00',
        dateModified: '2023-02-03T20:32:47+00:00',
        description: 'Simplify bookkeeping with Neat\'s mobile accounting software & storage. Automate reports, gain business insights, manage receipts & expenses. Try for free.',
        breadcrumb: { '@id': `${APP_URL}/balance-sheet-template/#breadcrumb` },
        inLanguage: 'en-US',
        potentialAction: [{ '@type': 'ReadAction', target: [`${APP_URL}/balance-sheet-template/`] }],
        primaryImageOfPage: { '@id': 'https://www.neat.com/balance-sheet-template/#primaryimage' },
      },
      breadCrumb: {
        itemListElements: [
          { position: 1, name: 'Home', item: APP_URL },
          { position: 2, name: 'Balance Sheet Template' },
        ],
      },
    },
    uri: '/balance-sheet-template/',
    additionalMetaTags: [
      {
        property: 'article:publisher',
        content: 'https://www.facebook.com/NeatCompany/',
      },
      {
        name: 'twitter:label1',
        content: 'Est. reading time',
      },
      {
        name: 'twitter:data1',
        content: '16 minutes',
      },
    ],
  },
  blog: {
    title: 'Resources for Small Businesses | The Neat Company',
    description: 'The Neat Blog provides articles and how tos on accounting, bookkeeping, and other topics relevant to small business owners.',
    canonical: `${APP_URL}/blog/`,
    twitter: {
      cardType: 'summary_large_image',
    },
    openGraph: {
      url: `${APP_URL}/blog/`,
      type: 'article',
      article: {
        modifiedTime: '2022-03-21T20:22:25+00:00',
      },
    },
    jsonLD: {
      webPage: {
        id: `${APP_URL}/blog/#webpage`,
        url: `${APP_URL}/blog/`,
        name: 'Resources for Small Businesses | The Neat Company',
        isPartOf: { '@id': `${APP_URL}/#website` },
        datePublished: '2019-08-15T17:58:59+00:00',
        dateModified: '2022-03-21T20:22:25+00:00',
        description: 'The Neat Blog provides articles and how tos on accounting, bookkeeping, and other topics relevant to small business owners.',
        breadcrumb: { '@id': `${APP_URL}/blog/#breadcrumb` },
        inLanguage: 'en-US',
        potentialAction: [{ '@type': 'ReadAction', target: [`${APP_URL}/blog/`] }],
        primaryImageOfPage: { '@id': 'https://www.neat.com/blog/#primaryimage' },
      },
      breadCrumb: {
        itemListElements: [
          { position: 1, name: 'Home', item: APP_URL },
          { position: 2, name: 'Blog Home Page' },
        ],
      },
    },
    uri: '/blog/',
    additionalMetaTags: [
      {
        property: 'article:publisher',
        content: 'https://www.facebook.com/NeatCompany/',
      },
      {
        name: 'twitter:label1',
        content: 'Est. reading time',
      },
      {
        name: 'twitter:data1',
        content: '7 minutes',
      },
    ],
  },
  careers: {
    title: 'Careers | The Neat Company',
    description: 'Neat is a highly collaborative environment geared towards creative problem solving and rapid decision making. Explore careers at Neat.',
    canonical: `${APP_URL}/careers/`,
    twitter: {
      cardType: 'summary_large_image',
    },
    openGraph: {
      url: `${APP_URL}/careers/`,
      type: 'article',
      article: {
        modifiedTime: '2022-09-07T13:30:13+00:00',
      },
    },
    jsonLD: {
      webPage: {
        id: `${APP_URL}/careers/#webpage`,
        url: `${APP_URL}/careers/`,
        name: 'Careers | The Neat Company',
        isPartOf: { '@id': `${APP_URL}/#website` },
        datePublished: '2019-07-01T18:25:20+00:00',
        dateModified: '2022-09-07T13:30:13+00:00',
        description: 'Neat is a highly collaborative environment geared towards creative problem solving and rapid decision making. Explore careers at Neat.',
        breadcrumb: { '@id': `${APP_URL}/careers/#breadcrumb` },
        inLanguage: 'en-US',
        potentialAction: [{ '@type': 'ReadAction', target: [`${APP_URL}/careers/`] }],
        primaryImageOfPage: { '@id': 'https://www.neat.com/careers/#primaryimage' },
      },
      breadCrumb: {
        itemListElements: [
          { position: 1, name: 'Home', item: APP_URL },
          { position: 2, name: 'Careers' },
        ],
      },
    },
    uri: '/careers/',
    additionalMetaTags: [
      {
        property: 'article:publisher',
        content: 'https://www.facebook.com/NeatCompany/',
      },
      {
        name: 'twitter:label1',
        content: 'Est. reading time',
      },
      {
        name: 'twitter:data1',
        content: '15 minutes',
      },
    ],
  },
  deleteAccount: { seo: {} },
  emailPreferences: {
    title: 'Email Preferences | The Neat Company',
    canonical: `${APP_URL}/email-preferences/`,
    twitter: {
      cardType: 'summary_large_image',
    },
    openGraph: {
      url: `${APP_URL}/email-preferences/`,
      type: 'article',
      article: {
        modifiedTime: '2022-03-21T18:34:41+00:00',
      },
    },
    jsonLD: {
      webPage: {
        id: `${APP_URL}/email-preferences/#webpage`,
        url: `${APP_URL}/email-preferences/`,
        name: 'Email Preferences | The Neat Company',
        isPartOf: { '@id': `${APP_URL}/#website` },
        datePublished: '2019-09-18T13:07:26+00:00',
        dateModified: '2022-03-21T18:34:41+00:00',
        breadcrumb: { '@id': `${APP_URL}/email-preferences/#breadcrumb` },
        inLanguage: 'en-US',
        potentialAction: [{ '@type': 'ReadAction', target: [`${APP_URL}/email-preferences/`] }],
        primaryImageOfPage: { '@id': 'https://www.neat.com/careers/#primaryimage' },
      },
      breadCrumb: {
        itemListElements: [
          { position: 1, name: 'Home', item: APP_URL },
          { position: 2, name: 'Email Preferences' },
        ],
      },
    },
    uri: '/email-preferences/',
    additionalMetaTags: [
      {
        property: 'article:publisher',
        content: 'https://www.facebook.com/NeatCompany/',
      },
      {
        name: 'twitter:label1',
        content: 'Est. reading time',
      },
      {
        name: 'twitter:data1',
        content: '5 minutes',
      },
    ],
  },
  fbRenew: {
    title: 'The Neat Company: Smarter receipt and document management',
    description: 'Transform the way you manage and organize your home or business. Unlimited file and document storage, expense tracking, bank-level encryption, and more! Try for free.',
    canonical: `${APP_URL}/fb-renew/`,
    twitter: {
      cardType: 'summary_large_image',
    },
    openGraph: {
      url: `${APP_URL}/fb-renew/`,
      type: 'article',
      article: {
        modifiedTime: '2023-03-29T13:34:24+00:00',
      },
    },
    jsonLD: {
      webPage: {
        id: `${APP_URL}/fb-renew/#webpage`,
        url: `${APP_URL}/fb-renew/`,
        name: 'The Neat Company: Smarter receipt and document management',
        description: 'Transform the way you manage and organize your home or business. Unlimited file and document storage, expense tracking, bank-level encryption, and more! Try for free.',
        isPartOf: { '@id': `${APP_URL}/#website` },
        datePublished: '2023-03-23T18:22:01+00:00',
        dateModified: '2023-03-29T13:34:24+00:00',
        breadcrumb: { '@id': `${APP_URL}/fb-renew/#breadcrumb` },
        inLanguage: 'en-US',
        potentialAction: [{ '@type': 'ReadAction', target: [`${APP_URL}/fb-renew/`] }],
        primaryImageOfPage: { '@id': 'https://www.neat.com/fb-renew/#primaryimage' },
      },
      breadCrumb: {
        itemListElements: [
          { position: 1, name: 'Home', item: APP_URL },
          { position: 2, name: 'Renew' },
        ],
      },
    },
    uri: '/fb-renew/',
    additionalMetaTags: [
      {
        property: 'article:publisher',
        content: 'https://www.facebook.com/NeatCompany/',
      },
      {
        name: 'twitter:label1',
        content: 'Est. reading time',
      },
      {
        name: 'twitter:data1',
        content: '6 minutes',
      },
    ],
  },
  home: {
    title: 'The Neat Company: Smarter receipt and document management',
    description: 'Transform the way you manage and organize your home or business. Unlimited file and document storage, expense tracking, bank-level encryption, and more! Try for free.',
    canonical: `${APP_URL}/`,
    twitter: {
      cardType: 'summary_large_image',
    },
    openGraph: {
      url: `${APP_URL}/`,
      type: 'article',
      article: {
        modifiedTime: '2023-03-02T19:09:24+00:00',
      },
    },
    jsonLD: {
      webPage: {
        id: `${APP_URL}/#webpage`,
        url: `${APP_URL}/`,
        name: 'The Neat Company: Smarter receipt and document management',
        description: 'Transform the way you manage and organize your home or business. Unlimited file and document storage, expense tracking, bank-level encryption, and more! Try for free.',
        isPartOf: { '@id': `${APP_URL}/#website` },
        datePublished: '2022-10-14T20:00:10+00:00',
        dateModified: '2023-03-02T19:09:24+00:00',
        breadcrumb: { '@id': `${APP_URL}/#breadcrumb` },
        inLanguage: 'en-US',
        potentialAction: [{ '@type': 'ReadAction', target: [`${APP_URL}/`] }],
        primaryImageOfPage: { '@id': 'https://www.neat.com/#primaryimage' },
      },
      breadCrumb: {
        itemListElements: [
          { position: 1, name: 'Home', item: APP_URL },
        ],
      },
    },
    uri: '/',
    additionalMetaTags: [
      {
        property: 'article:publisher',
        content: 'https://www.facebook.com/NeatCompany/',
      },
      {
        name: 'twitter:label1',
        content: 'Est. reading time',
      },
      {
        name: 'twitter:data1',
        content: '20 minutes',
      },
    ],
  },
  incomeStatementTemplate: {
    title: 'Income Statement Template | The Neat Company',
    description: 'This free Excel template is editable and is a great way to compare your income year over year.',
    canonical: `${APP_URL}/income-statement-template/`,
    twitter: {
      cardType: 'summary_large_image',
    },
    openGraph: {
      url: `${APP_URL}/income-statement-template/`,
      type: 'article',
      article: {
        modifiedTime: '2023-02-03T20:44:36+00:00',
      },
    },
    jsonLD: {
      webPage: {
        id: `${APP_URL}/income-statement-template/#webpage`,
        url: `${APP_URL}/income-statement-template/`,
        name: 'Income Statement Template | The Neat Company',
        description: 'This free Excel template is editable and is a great way to compare your income year over year.',
        isPartOf: { '@id': `${APP_URL}/income-statement-template/#website` },
        datePublished: '2021-10-27T19:09:07+00:00',
        dateModified: '2023-02-03T20:44:36+00:00',
        breadcrumb: { '@id': `${APP_URL}/income-statement-template/#breadcrumb` },
        inLanguage: 'en-US',
        potentialAction: [{ '@type': 'ReadAction', target: [`${APP_URL}/income-statement-template/`] }],
        primaryImageOfPage: { '@id': 'https://www.neat.com/income-statement-template/#primaryimage' },
      },
      breadCrumb: {
        itemListElements: [
          { position: 1, name: 'Home', item: APP_URL },
          { position: 2, name: 'Income Statement Template' },
        ],
      },
    },
    uri: '/income-statement-template',
    additionalMetaTags: [
      {
        property: 'article:publisher',
        content: 'https://www.facebook.com/NeatCompany/',
      },
      {
        name: 'twitter:label1',
        content: 'Est. reading time',
      },
      {
        name: 'twitter:data1',
        content: '17 minutes',
      },
    ],
  },
  integrateSeamlesslyWithQuickbooks: {
    title: 'Integrate with QuickBooks | Small Business Accounting Software | Neat',
    description: 'Using Neat with QuickBooks Online or QuickBooks Desktop simplifies accounting by reducing data entry and automatically organizing expenses.',
    canonical: `${APP_URL}/integrate-seamlessly-with-quickbooks/`,
    twitter: {
      cardType: 'summary_large_image',
    },
    openGraph: {
      url: `${APP_URL}/integrate-seamlessly-with-quickbooks/`,
      type: 'article',
      article: {
        modifiedTime: '2021-08-25T15:06:10+00:00',
      },
    },
    jsonLD: {
      webPage: {
        id: `${APP_URL}/integrate-seamlessly-with-quickbooks/#webpage`,
        url: `${APP_URL}/integrate-seamlessly-with-quickbooks/`,
        name: 'Integrate with QuickBooks | Small Business Accounting Software | Neat',
        description: 'Using Neat with QuickBooks Online or QuickBooks Desktop simplifies accounting by reducing data entry and automatically organizing expenses.',
        isPartOf: { '@id': `${APP_URL}/integrate-seamlessly-with-quickbooks/#website` },
        datePublished: '2020-06-30T14:17:47+00:00',
        dateModified: '2021-08-25T15:06:10+00:00',
        breadcrumb: { '@id': `${APP_URL}/integrate-seamlessly-with-quickbooks/#breadcrumb` },
        inLanguage: 'en-US',
        potentialAction: [{ '@type': 'ReadAction', target: [`${APP_URL}/integrate-seamlessly-with-quickbooks/`] }],
        primaryImageOfPage: { '@id': 'https://www.neat.com/integrate-seamlessly-with-quickbooks/#primaryimage' },
      },
      breadCrumb: {
        itemListElements: [
          { position: 1, name: 'Home', item: APP_URL },
          { position: 2, name: 'Income Statement Template' },
        ],
      },
    },
    uri: '/integrate-seamlessly-with-quickbooks',
    additionalMetaTags: [
      {
        property: 'article:publisher',
        content: 'https://www.facebook.com/NeatCompany/',
      },
      {
        name: 'twitter:label1',
        content: 'Est. reading time',
      },
      {
        name: 'twitter:data1',
        content: '10 minutes',
      },
    ],
  },
  integrations: {
    title: 'Receipt & Invoice Management Software Integrations | Neat',
    description: 'Neat extracts data from scanned receipts, invoices, and other documents and sends them to popular accounting, tax, and email software. Try free for 30 days.',
    canonical: `${APP_URL}/integrations/`,
    twitter: {
      cardType: 'summary_large_image',
    },
    openGraph: {
      url: `${APP_URL}/integrations/`,
      type: 'article',
      article: {
        modifiedTime: '2023-02-03T20:39:44+00:00',
      },
    },
    jsonLD: {
      webPage: {
        id: `${APP_URL}/integrations/#webpage`,
        url: `${APP_URL}/integrations/`,
        name: 'Receipt & Invoice Management Software Integrations | Neat',
        description: 'Neat extracts data from scanned receipts, invoices, and other documents and sends them to popular accounting, tax, and email software. Try free for 30 days.',
        isPartOf: { '@id': `${APP_URL}/integrations/#website` },
        datePublished: '2019-05-13T20:34:05+00:00',
        dateModified: '2023-02-03T20:39:44+00:00',
        breadcrumb: { '@id': `${APP_URL}/integrations/#breadcrumb` },
        inLanguage: 'en-US',
        potentialAction: [{ '@type': 'ReadAction', target: [`${APP_URL}/integrations/`] }],
        primaryImageOfPage: { '@id': 'https://www.neat.com/integrations/#primaryimage' },
      },
      breadCrumb: {
        itemListElements: [
          { position: 1, name: 'Home', item: APP_URL },
          { position: 2, name: 'Integrations' },
        ],
      },
    },
    uri: '/integrations',
    additionalMetaTags: [
      {
        property: 'article:publisher',
        content: 'https://www.facebook.com/NeatCompany/',
      },
      {
        name: 'twitter:label1',
        content: 'Est. reading time',
      },
      {
        name: 'twitter:data1',
        content: '10 minutes',
      },
    ],
  },
  integrationSupport: {
    title: 'Receipt & Invoice Management Software Integrations | Neat',
    description: 'Neat extracts data from scanned receipts, invoices, and other documents and sends them to popular accounting, tax, and email software. Try free for 30 days.',
    canonical: `${APP_URL}/integration-support/`,
    twitter: {
      cardType: 'summary_large_image',
    },
    openGraph: {
      url: `${APP_URL}/integration-support/`,
      type: 'article',
      article: {
        modifiedTime: '2020-01-29T15:49:01+00:00',
      },
    },
    jsonLD: {
      webPage: {
        id: `${APP_URL}/integration-support/#webpage`,
        url: `${APP_URL}/integration-support/`,
        name: 'Receipt & Invoice Management Software Integrations | Neat',
        description: 'Neat extracts data from scanned receipts, invoices, and other documents and sends them to popular accounting, tax, and email software. Try free for 30 days.',
        isPartOf: { '@id': `${APP_URL}/integration-support/#website` },
        datePublished: '2019-12-16T21:14:34+00:00',
        dateModified: '2020-01-29T15:49:01+00:00',
        breadcrumb: { '@id': `${APP_URL}/integration-support/#breadcrumb` },
        inLanguage: 'en-US',
        potentialAction: [{ '@type': 'ReadAction', target: [`${APP_URL}/integration-support/`] }],
        primaryImageOfPage: { '@id': 'https://www.neat.com/integration-support/#primaryimage' },
      },
      breadCrumb: {
        itemListElements: [
          { position: 1, name: 'Home', item: APP_URL },
          { position: 2, name: 'Integrations Support &#8211; Partner Page' },
        ],
      },
    },
    uri: '/integration-support',
    additionalMetaTags: [
      {
        property: 'article:publisher',
        content: 'https://www.facebook.com/NeatCompany/',
      },
      {
        name: 'twitter:label1',
        content: 'Est. reading time',
      },
      {
        name: 'twitter:data1',
        content: '15 minutes',
      },
    ],
  },
  invoiceTemplate: {
    title: 'Receipt & Invoice Management Software Integrations | Neat',
    description: 'Neat extracts data from scanned receipts, invoices, and other documents and sends them to popular accounting, tax, and email software. Try free for 30 days.',
    canonical: `${APP_URL}/invoice-template/`,
    twitter: {
      cardType: 'summary_large_image',
    },
    openGraph: {
      url: `${APP_URL}/invoice-template/`,
      type: 'article',
      article: {
        modifiedTime: '2023-02-03T20:43:44+00:00',
      },
    },
    jsonLD: {
      webPage: {
        id: `${APP_URL}/invoice-template/#webpage`,
        url: `${APP_URL}/invoice-template/`,
        name: 'Free Invoice Template | The Neat Company',
        description: 'Neat extracts data from scanned receipts, invoices, and other documents and sends them to popular accounting, tax, and email software. Try free for 30 days.',
        isPartOf: { '@id': `${APP_URL}/invoice-template/#website` },
        datePublished: '2021-10-12T19:58:53+00:00',
        dateModified: '2023-02-03T20:43:44+00:00',
        breadcrumb: { '@id': `${APP_URL}/invoice-template/#breadcrumb` },
        inLanguage: 'en-US',
        potentialAction: [{ '@type': 'ReadAction', target: [`${APP_URL}/invoice-template/`] }],
        primaryImageOfPage: { '@id': 'https://www.neat.com/integration-support/#primaryimage' },
      },
      breadCrumb: {
        itemListElements: [
          { position: 1, name: 'Home', item: APP_URL },
          { position: 2, name: 'Free Invoice Template' },
        ],
      },
    },
    uri: '/invoice-template',
    additionalMetaTags: [
      {
        property: 'article:publisher',
        content: 'https://www.facebook.com/NeatCompany/',
      },
      {
        name: 'twitter:label1',
        content: 'Est. reading time',
      },
      {
        name: 'twitter:data1',
        content: '44 minutes',
      },
    ],
  },
  neatvault: {
    title: 'The Neat Company: Small business finances made easy',
    description: 'Simplify bookkeeping with Neat\'s mobile accounting software & storage. Automate reports, gain business insights, manage receipts & expenses. Try for free.',
    canonical: `${APP_URL}/neatvault/`,
    twitter: {
      cardType: 'summary_large_image',
    },
    openGraph: {
      url: `${APP_URL}/neatvault/`,
      type: 'article',
      article: {
        modifiedTime: '2022-10-04T18:37:18+00:00',
      },
    },
    jsonLD: {
      webPage: {
        id: `${APP_URL}/neatvault/#webpage`,
        url: `${APP_URL}/neatvault/`,
        name: 'NeatVault | The Neat Company',
        description: 'Simplify bookkeeping with Neat\'s mobile accounting software & storage. Automate reports, gain business insights, manage receipts & expenses. Try for free.',
        isPartOf: { '@id': `${APP_URL}/neatvault/#website` },
        datePublished: '2022-09-23T20:41:38+00:00',
        dateModified: '2022-10-04T18:37:18+00:00',
        breadcrumb: { '@id': `${APP_URL}/neatvault/#breadcrumb` },
        inLanguage: 'en-US',
        potentialAction: [{ '@type': 'ReadAction', target: [`${APP_URL}/neatvault/`] }],
        primaryImageOfPage: { '@id': 'https://www.neat.com/integration-support/#primaryimage' },
      },
      breadCrumb: {
        itemListElements: [
          { position: 1, name: 'Home', item: APP_URL },
          { position: 2, name: 'NeatVault' },
        ],
      },
    },
    uri: '/neatvault',
    additionalMetaTags: [
      {
        property: 'article:publisher',
        content: 'https://www.facebook.com/NeatCompany/',
      },
      {
        name: 'twitter:label1',
        content: 'Est. reading time',
      },
      {
        name: 'twitter:data1',
        content: '10 minutes',
      },
    ],
  },
  offerNeatvault: {
    title: 'NeatVault | The Neat Company',
    description: 'Track invoices and payments all in one place to keep organization simple.',
    canonical: `${APP_URL}/offer-neatvault/`,
    twitter: {
      cardType: 'summary_large_image',
    },
    openGraph: {
      url: `${APP_URL}/offer-neatvault/`,
      type: 'article',
      article: {
        modifiedTime: '2023-02-03T20:53:19+00:00',
      },
    },
    jsonLD: {
      webPage: {
        id: `${APP_URL}/offer-neatvault/#webpage`,
        url: `${APP_URL}/offer-neatvault/`,
        name: 'NeatVault | The Neat Company',
        description: 'Track invoices and payments all in one place to keep organization simple.',
        isPartOf: { '@id': `${APP_URL}/offer-neatvault/#website` },
        datePublished: '2022-11-15T15:44:57+00:00',
        dateModified: '2023-02-03T20:53:19+00:00',
        breadcrumb: { '@id': `${APP_URL}/offer-neatvault/#breadcrumb` },
        inLanguage: 'en-US',
        potentialAction: [{ '@type': 'ReadAction', target: [`${APP_URL}/offer-neatvault/`] }],
        primaryImageOfPage: { '@id': 'https://www.neat.com/integration-support/#primaryimage' },
      },
      breadCrumb: {
        itemListElements: [
          { position: 1, name: 'Home', item: APP_URL },
          { position: 2, name: 'NeatVault' },
        ],
      },
    },
    uri: '/offer-neatvault',
    additionalMetaTags: [
      {
        property: 'article:publisher',
        content: 'https://www.facebook.com/NeatCompany/',
      },
      {
        name: 'twitter:label1',
        content: 'Est. reading time',
      },
      {
        name: 'twitter:data1',
        content: '10 minutes',
      },
    ],
  },
  ourHistory: {
    title: 'Our History | Neat',
    description: 'Our vision is to accelerate small businesses\' transition to a world where keeping books is simple, frictionless, instant, and automated.',
    canonical: `${APP_URL}/our-history/`,
    twitter: {
      cardType: 'summary_large_image',
    },
    openGraph: {
      url: `${APP_URL}/our-history/`,
      type: 'article',
      article: {
        modifiedTime: '2022-10-31T16:53:00+00:00',
      },
    },
    jsonLD: {
      webPage: {
        id: `${APP_URL}/our-history/#webpage`,
        url: `${APP_URL}/our-history/`,
        name: 'Our History | Neat',
        description: 'Our vision is to accelerate small businesses\' transition to a world where keeping books is simple, frictionless, instant, and automated.',
        isPartOf: { '@id': `${APP_URL}/our-history/#website` },
        datePublished: '2019-06-28T14:43:25+00:00',
        dateModified: '2022-10-31T16:53:00+00:00',
        breadcrumb: { '@id': `${APP_URL}/our-history/#breadcrumb` },
        inLanguage: 'en-US',
        potentialAction: [{ '@type': 'ReadAction', target: [`${APP_URL}/our-history/`] }],
        primaryImageOfPage: { '@id': 'https://www.neat.com/integration-support/#primaryimage' },
      },
      breadCrumb: {
        itemListElements: [
          { position: 1, name: 'Home', item: APP_URL },
          { position: 2, name: 'Our History' },
        ],
      },
    },
    uri: '/our-history',
    additionalMetaTags: [
      {
        property: 'article:publisher',
        content: 'https://www.facebook.com/NeatCompany/',
      },
      {
        name: 'twitter:label1',
        content: 'Est. reading time',
      },
      {
        name: 'twitter:data1',
        content: '24 minutes',
      },
    ],
  },
  policies: {
    title: 'Neat Policies | The Neat Company',
    canonical: `${APP_URL}/policies/`,
    twitter: {
      cardType: 'summary_large_image',
    },
    openGraph: {
      url: `${APP_URL}/policies/`,
      type: 'article',
      article: {
        modifiedTime: '2022-03-30T19:58:37+00:00',
      },
    },
    jsonLD: {
      webPage: {
        id: `${APP_URL}/policies/#webpage`,
        url: `${APP_URL}/policies/`,
        name: 'Neat Policies | The Neat Company',
        isPartOf: { '@id': `${APP_URL}/policies/#website` },
        datePublished: '2019-10-04T13:34:48+00:00',
        dateModified: '2022-03-30T19:58:37+00:00',
        breadcrumb: { '@id': `${APP_URL}/policies/#breadcrumb` },
        inLanguage: 'en-US',
        potentialAction: [{ '@type': 'ReadAction', target: [`${APP_URL}/policies/`] }],
        primaryImageOfPage: { '@id': 'https://www.neat.com/integration-support/#primaryimage' },
      },
      breadCrumb: {
        itemListElements: [
          { position: 1, name: 'Home', item: APP_URL },
          { position: 2, name: 'Neat Policies' },
        ],
      },
    },
    uri: '/policies',
    additionalMetaTags: [
      {
        property: 'article:publisher',
        content: 'https://www.facebook.com/NeatCompany/',
      },
      {
        name: 'twitter:label1',
        content: 'Est. reading time',
      },
      {
        name: 'twitter:data1',
        content: '9 minutes',
      },
    ],
  },
  pricing: {
    title: 'The Neat Company: Small business finances made easy',
    description: 'Simplify bookkeeping with Neat\'s mobile accounting software & storage. Automate reports, gain business insights, manage receipts & expenses. Try for free.',
    canonical: `${APP_URL}/pricing/`,
    twitter: {
      cardType: 'summary_large_image',
    },
    openGraph: {
      url: `${APP_URL}/pricing/`,
      type: 'article',
      article: {
        modifiedTime: '2023-03-13T15:51:09+00:00',
      },
    },
    jsonLD: {
      webPage: {
        id: `${APP_URL}/pricing/#webpage`,
        url: `${APP_URL}/pricing/`,
        name: 'The Neat Company: Small business finances made easy',
        description: 'Simplify bookkeeping with Neat\'s mobile accounting software & storage. Automate reports, gain business insights, manage receipts & expenses. Try for free.',
        isPartOf: { '@id': `${APP_URL}/pricing/#website` },
        datePublished: '2022-11-04T22:43:05+00:00',
        dateModified: '2023-03-13T15:51:09+00:00',
        breadcrumb: { '@id': `${APP_URL}/pricing/#breadcrumb` },
        inLanguage: 'en-US',
        potentialAction: [{ '@type': 'ReadAction', target: [`${APP_URL}/pricing/`] }],
        primaryImageOfPage: { '@id': 'https://www.neat.com/integration-support/#primaryimage' },
      },
      breadCrumb: {
        itemListElements: [
          { position: 1, name: 'Home', item: APP_URL },
          { position: 2, name: 'Pricing' },
        ],
      },
    },
    uri: '/pricing',
    additionalMetaTags: [
      {
        property: 'article:publisher',
        content: 'https://www.facebook.com/NeatCompany/',
      },
      {
        name: 'twitter:label1',
        content: 'Est. reading time',
      },
      {
        name: 'twitter:data1',
        content: '7 minutes',
      },
    ],
  },
  privacy: {
    title: 'Privacy Policy | The Neat Company',
    canonical: `${APP_URL}/policies/privacy/`,
    twitter: {
      cardType: 'summary_large_image',
    },
    openGraph: {
      url: `${APP_URL}/policies/privacy/`,
      type: 'article',
      article: {
        modifiedTime: '2021-04-06T13:38:13+00:00',
      },
    },
    jsonLD: {
      webPage: {
        id: `${APP_URL}/policies/privacy/#webpage`,
        url: `${APP_URL}/policies/privacy/`,
        name: 'Privacy Policy | The Neat Company',
        isPartOf: { '@id': `${APP_URL}/policies/privacy/#website` },
        datePublished: '2019-07-11T19:31:47+00:00',
        dateModified: '2021-04-06T13:38:13+00:00',
        breadcrumb: { '@id': `${APP_URL}/policies/privacy/#breadcrumb` },
        inLanguage: 'en-US',
        potentialAction: [{ '@type': 'ReadAction', target: [`${APP_URL}/policies/privacy/`] }],
        primaryImageOfPage: { '@id': 'https://www.neat.com/integration-support/#primaryimage' },
      },
      breadCrumb: {
        itemListElements: [
          { position: 1, name: 'Home', item: APP_URL },
          { position: 2, name: 'Neat Policies', item: `{${APP_URL}/policies` },
          { position: 3, name: 'Pricing' },
        ],
      },
    },
    uri: '/policies/privacy',
    additionalMetaTags: [
      {
        property: 'article:publisher',
        content: 'https://www.facebook.com/NeatCompany/',
      },
      {
        name: 'twitter:label1',
        content: 'Est. reading time',
      },
      {
        name: 'twitter:data1',
        content: '18 minutes',
      },
    ],
  },
  productReleases: {
    title: 'Product Releases for Neat\'s Bookkeeping Software | The Neat Company',
    description: 'We\'re always looking to create the best experience possible. Here is you can find all of our latest product updates for Neat\'s solutions.',
    canonical: `${APP_URL}/product-releases/`,
    twitter: {
      cardType: 'summary_large_image',
    },
    openGraph: {
      url: `${APP_URL}/product-releases/`,
      type: 'article',
      article: {
        modifiedTime: '2022-08-17T16:26:11+00:00',
      },
    },
    jsonLD: {
      webPage: {
        id: `${APP_URL}/product-releases/#webpage`,
        url: `${APP_URL}/product-releases/`,
        name: 'Product Releases for Neat\'s Bookkeeping Software | The Neat Company',
        description: 'We\'re always looking to create the best experience possible. Here is you can find all of our latest product updates for Neat\'s solutions.',
        isPartOf: { '@id': `${APP_URL}/product-releases/#website` },
        datePublished: '2020-07-10T18:38:46+00:00',
        dateModified: '2022-08-17T16:26:11+00:00',
        breadcrumb: { '@id': `${APP_URL}/product-releases/#breadcrumb` },
        inLanguage: 'en-US',
        potentialAction: [{ '@type': 'ReadAction', target: [`${APP_URL}/product-releases/`] }],
        primaryImageOfPage: { '@id': 'https://www.neat.com/product-releases/#primaryimage' },
      },
      breadCrumb: {
        itemListElements: [
          { position: 1, name: 'Home', item: APP_URL },
          { position: 2, name: 'Product Releases' },
        ],
      },
    },
    uri: '/product-releases',
    additionalMetaTags: [
      {
        property: 'article:publisher',
        content: 'https://www.facebook.com/NeatCompany/',
      },
      {
        name: 'twitter:label1',
        content: 'Est. reading time',
      },
      {
        name: 'twitter:data1',
        content: '94 minutes',
      },
    ],
  },
  receiptDocumentScanners: {
    title: 'Compatible Receipt and Document Scanners | Neat',
    description: 'Neat works with any TWAIN compliant scanner, which includes most scanners on the market today. Connecting these devices is covered as part of our plans.',
    canonical: `${APP_URL}/receipt-document-scanners/`,
    twitter: {
      cardType: 'summary_large_image',
    },
    openGraph: {
      url: `${APP_URL}/receipt-document-scanners/`,
      type: 'article',
      article: {
        modifiedTime: '2023-02-03T20:41:18+00:00',
      },
    },
    jsonLD: {
      webPage: {
        id: `${APP_URL}/receipt-document-scanners/#webpage`,
        url: `${APP_URL}/receipt-document-scanners/`,
        name: 'Compatible Receipt and Document Scanners | Neat',
        description: 'Neat works with any TWAIN compliant scanner, which includes most scanners on the market today. Connecting these devices is covered as part of our plans.',
        isPartOf: { '@id': `${APP_URL}/receipt-document-scanners/#website` },
        datePublished: '2019-09-06T16:28:43+00:00',
        dateModified: '2023-02-03T20:41:18+00:00',
        breadcrumb: { '@id': `${APP_URL}/receipt-document-scanners/#breadcrumb` },
        inLanguage: 'en-US',
        potentialAction: [{ '@type': 'ReadAction', target: [`${APP_URL}/receipt-document-scanners/`] }],
        primaryImageOfPage: { '@id': 'https://www.neat.com/receipt-document-scanners/#primaryimage' },
      },
      breadCrumb: {
        itemListElements: [
          { position: 1, name: 'Home', item: APP_URL },
          { position: 2, name: 'Compatible Scanners' },
        ],
      },
    },
    uri: '/receipt-document-scanners',
    additionalMetaTags: [
      {
        property: 'article:publisher',
        content: 'https://www.facebook.com/NeatCompany/',
      },
      {
        name: 'twitter:label1',
        content: 'Est. reading time',
      },
      {
        name: 'twitter:data1',
        content: '17 minutes',
      },
    ],
  },
  referral: {
    title: 'Compatible Receipt and Document Scanners | Neat',
    description: 'Neat works with any TWAIN compliant scanner, which includes most scanners on the market today. Connecting these devices is covered as part of our plans.',
    canonical: `${APP_URL}/referral/`,
    twitter: {
      cardType: 'summary_large_image',
    },
    openGraph: {
      url: `${APP_URL}/referral/`,
      type: 'article',
      article: {
        modifiedTime: '2023-02-07T14:46:58+00:00',
      },
    },
    jsonLD: {
      webPage: {
        id: `${APP_URL}/referral/#webpage`,
        url: `${APP_URL}/referral/`,
        name: 'Compatible Receipt and Document Scanners | Neat',
        description: 'Neat works with any TWAIN compliant scanner, which includes most scanners on the market today. Connecting these devices is covered as part of our plans.',
        isPartOf: { '@id': `${APP_URL}/referral/#website` },
        datePublished: '2022-12-08T20:25:25+00:00',
        dateModified: '2023-02-07T14:46:58+00:00',
        breadcrumb: { '@id': `${APP_URL}/referral/#breadcrumb` },
        inLanguage: 'en-US',
        potentialAction: [{ '@type': 'ReadAction', target: [`${APP_URL}/referral/`] }],
        primaryImageOfPage: { '@id': 'https://www.neat.com/referral/#primaryimage' },
      },
      breadCrumb: {
        itemListElements: [
          { position: 1, name: 'Home', item: APP_URL },
          { position: 2, name: 'Referral' },
        ],
      },
    },
    uri: '/referral',
    additionalMetaTags: [
      {
        property: 'article:publisher',
        content: 'https://www.facebook.com/NeatCompany/',
      },
      {
        name: 'twitter:label1',
        content: 'Est. reading time',
      },
      {
        name: 'twitter:data1',
        content: '3 minutes',
      },
    ],
  },
  referralTermsConditions: {
    title: 'Referral Terms and Conditions | The Neat Company',
    canonical: `${APP_URL}/referral-terms-conditions/`,
    twitter: {
      cardType: 'summary_large_image',
    },
    openGraph: {
      url: `${APP_URL}/referral-terms-conditions/`,
      type: 'article',
      article: {
        modifiedTime: '2023-03-30T16:01:58+00:00',
      },
    },
    jsonLD: {
      webPage: {
        id: `${APP_URL}/referral-terms-conditions/#webpage`,
        url: `${APP_URL}/referral-terms-conditions/`,
        name: 'Referral Terms and Conditions | The Neat Company',
        isPartOf: { '@id': `${APP_URL}/referral-terms-conditions/#website` },
        datePublished: '2023-03-30T15:15:20+00:00',
        dateModified: '2023-03-30T16:01:58+00:00',
        breadcrumb: { '@id': `${APP_URL}/referral-terms-conditions/#breadcrumb` },
        inLanguage: 'en-US',
        potentialAction: [{ '@type': 'ReadAction', target: [`${APP_URL}/referral-terms-conditions/`] }],
        primaryImageOfPage: { '@id': 'https://www.neat.com/referral-terms-conditions/#primaryimage' },
      },
      breadCrumb: {
        itemListElements: [
          { position: 1, name: 'Home', item: APP_URL },
          { position: 2, name: 'Referral Terms and Conditions' },
        ],
      },
    },
    uri: '/referral-terms-conditions',
    additionalMetaTags: [
      {
        property: 'article:publisher',
        content: 'https://www.facebook.com/NeatCompany/',
      },
      {
        name: 'twitter:label1',
        content: 'Est. reading time',
      },
      {
        name: 'twitter:data1',
        content: '4 minutes',
      },
    ],
  },
  renew: {
    title: 'The Neat Company: Smarter receipt and document management',
    description: 'Transform the way you manage and organize your home or business. Unlimited file and document storage, expense tracking, bank-level encryption, and more! Try for free.',
    canonical: `${APP_URL}/renew/`,
    twitter: {
      cardType: 'summary_large_image',
    },
    openGraph: {
      url: `${APP_URL}/renew/`,
      type: 'article',
      article: {
        modifiedTime: '2023-03-29T13:35:08+00:00',
      },
    },
    jsonLD: {
      webPage: {
        id: `${APP_URL}/renew/#webpage`,
        url: `${APP_URL}/renew/`,
        name: 'The Neat Company: Smarter receipt and document management',
        description: 'Transform the way you manage and organize your home or business. Unlimited file and document storage, expense tracking, bank-level encryption, and more! Try for free.',
        isPartOf: { '@id': `${APP_URL}/renew/#website` },
        datePublished: '2023-01-20T14:11:31+00:00',
        dateModified: '2023-03-29T13:35:08+00:00',
        breadcrumb: { '@id': `${APP_URL}/renew/#breadcrumb` },
        inLanguage: 'en-US',
        potentialAction: [{ '@type': 'ReadAction', target: [`${APP_URL}/renew/`] }],
        primaryImageOfPage: { '@id': 'https://www.neat.com/renew/#primaryimage' },
      },
      breadCrumb: {
        itemListElements: [
          { position: 1, name: 'Home', item: APP_URL },
          { position: 2, name: 'Renew' },
        ],
      },
    },
    uri: '/renew',
    additionalMetaTags: [
      {
        property: 'article:publisher',
        content: 'https://www.facebook.com/NeatCompany/',
      },
      {
        name: 'twitter:label1',
        content: 'Est. reading time',
      },
      {
        name: 'twitter:data1',
        content: '6 minutes',
      },
    ],
  },
  policiesSalesTax: {
    title: 'Sales Tax Policy | The Neat Company',
    canonical: `${APP_URL}/policies/sales-tax/`,
    twitter: {
      cardType: 'summary_large_image',
    },
    openGraph: {
      url: `${APP_URL}/policies/sales-tax/`,
      type: 'article',
      article: {
        modifiedTime: '2023-03-01T15:33:53+00:00',
      },
    },
    jsonLD: {
      webPage: {
        id: `${APP_URL}/policies/sales-tax/#webpage`,
        url: `${APP_URL}/policies/sales-tax/`,
        name: 'Sales Tax Policy | The Neat Company',
        isPartOf: { '@id': `${APP_URL}/policies/sales-tax/#website` },
        datePublished: '2019-10-09T20:32:09+00:00',
        dateModified: '2023-03-01T15:33:53+00:00',
        breadcrumb: { '@id': `${APP_URL}/policies/sales-tax/#breadcrumb` },
        inLanguage: 'en-US',
        potentialAction: [{ '@type': 'ReadAction', target: [`${APP_URL}/policies/sales-tax/`] }],
        primaryImageOfPage: { '@id': 'https://www.neat.com/policies/sales-tax/#primaryimage' },
      },
      breadCrumb: {
        itemListElements: [
          { position: 1, name: 'Home', item: APP_URL },
          { position: 2, name: 'Neat Policies', item: `${APP_URL}/policies/` },
          { position: 3, name: 'Sales Tax Policy' },
        ],
      },
    },
    uri: '/policies/sales-tax',
    additionalMetaTags: [
      {
        property: 'article:publisher',
        content: 'https://www.facebook.com/NeatCompany/',
      },
      {
        name: 'twitter:label1',
        content: 'Est. reading time',
      },
      {
        name: 'twitter:data1',
        content: '7 minutes',
      },
    ],
  },
  security: {
    title: 'Security Features | Neat',
    description: 'We employ best-in-class security features to keep your information protected and safe. Try free for 15 days. No credit card required.',
    canonical: `${APP_URL}/security/`,
    twitter: {
      cardType: 'summary_large_image',
    },
    openGraph: {
      url: `${APP_URL}/security/`,
      type: 'article',
      article: {
        modifiedTime: '2023-02-03T20:40:33+00:00',
      },
    },
    jsonLD: {
      webPage: {
        id: `${APP_URL}/security/#webpage`,
        url: `${APP_URL}/security/`,
        name: 'Security Features | Neat',
        description: 'We employ best-in-class security features to keep your information protected and safe. Try free for 15 days. No credit card required.',
        isPartOf: { '@id': `${APP_URL}/security/#website` },
        datePublished: '2019-09-06T15:29:29+00:00',
        dateModified: '2023-02-03T20:40:33+00:00',
        breadcrumb: { '@id': `${APP_URL}/security/#breadcrumb` },
        inLanguage: 'en-US',
        potentialAction: [{ '@type': 'ReadAction', target: [`${APP_URL}/security/`] }],
        primaryImageOfPage: { '@id': 'https://www.neat.com/policies/security/#primaryimage' },
      },
      breadCrumb: {
        itemListElements: [
          { position: 1, name: 'Home', item: APP_URL },
          { position: 2, name: 'Security' },
        ],
      },
    },
    uri: '/security',
    additionalMetaTags: [
      {
        property: 'article:publisher',
        content: 'https://www.facebook.com/NeatCompany/',
      },
      {
        name: 'twitter:label1',
        content: 'Est. reading time',
      },
      {
        name: 'twitter:data1',
        content: '15 minutes',
      },
    ],
  },
  signUp: { seo: {} },
  taxPrep: {
    title: 'Invoice & Receipt Tracker For Tax Preparation | Neat',
    description: 'Neat\'s document and receipt tracker helps business owners categorize, report on, and store financial documents without manual data entry. Try for free!',
    canonical: `${APP_URL}/tax-prep/`,
    twitter: {
      cardType: 'summary_large_image',
    },
    openGraph: {
      url: `${APP_URL}/tax-prep/`,
      type: 'article',
      article: {
        modifiedTime: '2023-02-03T20:39:04+00:00',
      },
    },
    jsonLD: {
      webPage: {
        id: `${APP_URL}/tax-prep/#webpage`,
        url: `${APP_URL}/tax-prep/`,
        name: 'Invoice & Receipt Tracker For Tax Preparation | Neat',
        description: 'Neat\'s document and receipt tracker helps business owners categorize, report on, and store financial documents without manual data entry. Try for free!',
        isPartOf: { '@id': `${APP_URL}/tax-prep/#website` },
        datePublished: '2019-08-26T18:09:07+00:00',
        dateModified: '2023-02-03T20:39:04+00:00',
        breadcrumb: { '@id': `${APP_URL}/tax-prep/#breadcrumb` },
        inLanguage: 'en-US',
        potentialAction: [{ '@type': 'ReadAction', target: [`${APP_URL}/tax-prep/`] }],
        primaryImageOfPage: { '@id': 'https://www.neat.com/policies/tax-prep/#primaryimage' },
      },
      breadCrumb: {
        itemListElements: [
          { position: 1, name: 'Home', item: APP_URL },
          { position: 2, name: 'Tax Preparation Features Page' },
        ],
      },
    },
    uri: '/tax-prep',
    additionalMetaTags: [
      {
        property: 'article:publisher',
        content: 'https://www.facebook.com/NeatCompany/',
      },
      {
        name: 'twitter:label1',
        content: 'Est. reading time',
      },
      {
        name: 'twitter:data1',
        content: '20 minutes',
      },
    ],
  },
  termsConditions: {
    title: 'Terms & Conditions | The Neat Company',
    canonical: `${APP_URL}/terms-conditions/`,
    twitter: {
      cardType: 'summary_large_image',
    },
    openGraph: {
      url: `${APP_URL}/terms-conditions/`,
      type: 'article',
      article: {
        modifiedTime: '2023-03-27T19:43:01+00:00',
      },
    },
    jsonLD: {
      webPage: {
        id: `${APP_URL}/terms-conditions/#webpage`,
        url: `${APP_URL}/terms-conditions/`,
        name: 'Terms & Conditions | The Neat Company',
        isPartOf: { '@id': `${APP_URL}/terms-conditions/#website` },
        datePublished: '2019-07-11T19:30:35+00:00',
        dateModified: '2023-03-27T19:43:01+00:00',
        breadcrumb: { '@id': `${APP_URL}/terms-conditions/#breadcrumb` },
        inLanguage: 'en-US',
        potentialAction: [{ '@type': 'ReadAction', target: [`${APP_URL}/terms-conditions/`] }],
        primaryImageOfPage: { '@id': 'https://www.neat.com/policies/terms-conditions/#primaryimage' },
      },
      breadCrumb: {
        itemListElements: [
          { position: 1, name: 'Home', item: APP_URL },
          { position: 2, name: 'Terms &#038; Conditions' },
        ],
      },
    },
    uri: '/terms-conditions',
    additionalMetaTags: [
      {
        property: 'article:publisher',
        content: 'https://www.facebook.com/NeatCompany/',
      },
      {
        name: 'twitter:label1',
        content: 'Est. reading time',
      },
      {
        name: 'twitter:data1',
        content: '18 minutes',
      },
    ],
  },
  trackReceipts: {
    title: 'Invoice & Receipt Tracker For Desktop, iPhone & Android | Neat',
    description: 'Neat\'s invoice and receipt tracker helps businesses store and organize financial documents with their scanner or mobile phone. Try free for 15 days.',
    canonical: `${APP_URL}/track-receipts/`,
    twitter: {
      cardType: 'summary_large_image',
    },
    openGraph: {
      url: `${APP_URL}/track-receipts/`,
      type: 'article',
      article: {
        modifiedTime: '2023-02-03T20:37:29+00:00',
      },
    },
    jsonLD: {
      webPage: {
        id: `${APP_URL}/track-receipts/#webpage`,
        url: `${APP_URL}/track-receipts/`,
        name: 'Invoice & Receipt Tracker For Desktop, iPhone & Android | Neat',
        description: 'Neat\'s invoice and receipt tracker helps businesses store and organize financial documents with their scanner or mobile phone. Try free for 15 days.',
        isPartOf: { '@id': `${APP_URL}/track-receipts/#website` },
        datePublished: '2023-02-01T21:30:52+00:00',
        dateModified: '2023-02-03T20:37:29+00:00',
        breadcrumb: { '@id': `${APP_URL}/track-receipts/#breadcrumb` },
        inLanguage: 'en-US',
        potentialAction: [{ '@type': 'ReadAction', target: [`${APP_URL}/track-receipts/`] }],
        primaryImageOfPage: { '@id': 'https://www.neat.com/policies/track-receipts/#primaryimage' },
      },
      breadCrumb: {
        itemListElements: [
          { position: 1, name: 'Home', item: APP_URL },
          { position: 2, name: 'Track Receipts &#038; Invoices' },
        ],
      },
    },
    uri: '/track-receipts',
    additionalMetaTags: [
      {
        property: 'article:publisher',
        content: 'https://www.facebook.com/NeatCompany/',
      },
      {
        name: 'twitter:label1',
        content: 'Est. reading time',
      },
      {
        name: 'twitter:data1',
        content: '10 minutes',
      },
    ],
  },
  neatVsExpensify: {
    title: 'Neat VS Expensify | Neat',
    description: 'Neat\'s invoice and receipt tracker helps businesses store and organize financial documents with their scanner or mobile phone. Try free for 15 days.',
    canonical: `${APP_URL}/neat-vs-expensify/`,
    twitter: {
      cardType: 'summary_large_image',
    },
    openGraph: {
      url: `${APP_URL}/neat-vs-expensify/`,
      type: 'article',
      article: {
        modifiedTime: '2023-02-03T20:37:29+00:00',
      },
    },
    jsonLD: {
      webPage: {
        id: `${APP_URL}/neat-vs-expensify/#webpage`,
        url: `${APP_URL}/neat-vs-expensify/`,
        name: 'Neat VS Competitor | Neat',
        description: 'Neat VS Competitor',
        isPartOf: { '@id': `${APP_URL}/neat-vs-expensify/#website` },
        datePublished: '2023-02-01T21:30:52+00:00',
        dateModified: '2023-02-03T20:37:29+00:00',
        breadcrumb: { '@id': `${APP_URL}/neat-vs-expensify/#breadcrumb` },
        inLanguage: 'en-US',
        potentialAction: [{ '@type': 'ReadAction', target: [`${APP_URL}/neat-vs-expensify/`] }],
        primaryImageOfPage: { '@id': 'https://www.neat.com/policies/neat-vs-expensify/#primaryimage' },
      },
      breadCrumb: {
        itemListElements: [
          { position: 1, name: 'Home', item: APP_URL },
          { position: 2, name: 'Neat VS Competitor' },
        ],
      },
    },
    uri: '/neat-vs-expensify',
    additionalMetaTags: [
      {
        property: 'article:publisher',
        content: 'https://www.facebook.com/NeatCompany/',
      },
      {
        name: 'twitter:label1',
        content: 'Est. reading time',
      },
      {
        name: 'twitter:data1',
        content: '10 minutes',
      },
    ],
  },
  transactionStreaming: {
    title: 'Document Matching & Reconciliation Software| Neat',
    description: 'With Neat, paper receipts, bank transactions, and invoices are all consolidated for one-screen reconciliation. Start your free trial.',
    canonical: `${APP_URL}/transaction-streaming/`,
    twitter: {
      cardType: 'summary_large_image',
    },
    openGraph: {
      url: `${APP_URL}/transaction-streaming/`,
      type: 'article',
      article: {
        modifiedTime: '2023-02-03T20:38:16+00:00',
      },
    },
    jsonLD: {
      webPage: {
        id: `${APP_URL}/transaction-streaming/#webpage`,
        url: `${APP_URL}/transaction-streaming/`,
        name: 'Document Matching & Reconciliation Software| Neat',
        description: 'With Neat, paper receipts, bank transactions, and invoices are all consolidated for one-screen reconciliation. Start your free trial.',
        isPartOf: { '@id': `${APP_URL}/transaction-streaming/#website` },
        datePublished: '2019-09-25T18:39:23+00:00',
        dateModified: '2023-02-03T20:38:16+00:00',
        breadcrumb: { '@id': `${APP_URL}/transaction-streaming/#breadcrumb` },
        inLanguage: 'en-US',
        potentialAction: [{ '@type': 'ReadAction', target: [`${APP_URL}/transaction-streaming/`] }],
        primaryImageOfPage: { '@id': 'https://www.neat.com/transaction-streaming/tax-prep/#primaryimage' },
      },
      breadCrumb: {
        itemListElements: [
          { position: 1, name: 'Home', item: APP_URL },
          { position: 2, name: 'Transaction Matching &#038; Streaming' },
        ],
      },
    },
    uri: '/transaction-streaming',
    additionalMetaTags: [
      {
        property: 'article:publisher',
        content: 'https://www.facebook.com/NeatCompany/',
      },
      {
        name: 'twitter:label1',
        content: 'Est. reading time',
      },
      {
        name: 'twitter:data1',
        content: '16 minutes',
      },
    ],
  },
  vipService: {
    title: 'The Neat Company: Small business finances made easy',
    description: 'Simplify bookkeeping with Neat\'s mobile accounting software & storage. Automate reports, gain business insights, manage receipts & expenses. Try for free.',
    canonical: `${APP_URL}/vip-service/`,
    twitter: {
      cardType: 'summary_large_image',
    },
    openGraph: {
      url: `${APP_URL}/vip-service/`,
      type: 'article',
      article: {
        modifiedTime: '2023-02-03T20:33:39+00:00',
      },
    },
    jsonLD: {
      webPage: {
        id: `${APP_URL}/vip-service/#webpage`,
        url: `${APP_URL}/vip-service/`,
        name: 'The Neat Company: Small business finances made easy',
        description: 'Simplify bookkeeping with Neat\'s mobile accounting software & storage. Automate reports, gain business insights, manage receipts & expenses. Try for free.',
        isPartOf: { '@id': `${APP_URL}/vip-service/#website` },
        datePublished: '2022-10-21T18:27:45+00:00',
        dateModified: '2023-02-03T20:33:39+00:00',
        breadcrumb: { '@id': `${APP_URL}/vip-service/#breadcrumb` },
        inLanguage: 'en-US',
        potentialAction: [{ '@type': 'ReadAction', target: [`${APP_URL}/vip-service/`] }],
        primaryImageOfPage: { '@id': 'https://www.neat.com/vip-service/tax-prep/#primaryimage' },
      },
      breadCrumb: {
        itemListElements: [
          { position: 1, name: 'Home', item: APP_URL },
          { position: 2, name: 'VIP Service' },
        ],
      },
    },
    uri: '/vip-service',
    additionalMetaTags: [
      {
        property: 'article:publisher',
        content: 'https://www.facebook.com/NeatCompany/',
      },
      {
        name: 'twitter:label1',
        content: 'Est. reading time',
      },
      {
        name: 'twitter:data1',
        content: '15 minutes',
      },
    ],
  },
  webinars: {
    title: 'Webinars and Replays | The Neat Company',
    description: 'Access on-demand Neat webinars for exclusive tips and tricks, unlocking the full potential of Neat.',
    canonical: `${APP_URL}/webinars/`,
    twitter: {
      cardType: 'summary_large_image',
    },
    openGraph: {
      url: `${APP_URL}/webinars/`,
      type: 'article',
      article: {
        modifiedTime: '2023-09-26T18:10:23+00:00',
      },
    },
    jsonLD: {
      webPage: {
        id: `${APP_URL}/webinars/#webpage`,
        url: `${APP_URL}/webinars/`,
        name: 'Webinars and Replays | The Neat Company',
        isPartOf: { '@id': `${APP_URL}/#website` },
        datePublished: '2023-09-26T18:10:23+00:00',
        dateModified: '2023-09-26T18:10:23+00:00',
        description: 'Access on-demand Neat webinars for exclusive tips and tricks, unlocking the full potential of Neat.',
        breadcrumb: { '@id': `${APP_URL}/webinars/#breadcrumb` },
        inLanguage: 'en-US',
        potentialAction: [{ '@type': 'ReadAction', target: [`${APP_URL}/webinars/`] }],
        primaryImageOfPage: { '@id': 'https://www.neat.com/webinars/#primaryimage' },
      },
      breadCrumb: {
        itemListElements: [
          { position: 1, name: 'Home', item: APP_URL },
          { position: 2, name: 'Webinars' },
        ],
      },
    },
    uri: '/webinars/',
    additionalMetaTags: [
      {
        property: 'article:publisher',
        content: 'https://www.facebook.com/NeatCompany/',
      },
      {
        name: 'twitter:label1',
        content: 'Est. reading time',
      },
      {
        name: 'twitter:data1',
        content: '11 minutes',
      },
    ],
  },
  freeTrial: {
    title: 'Free trial with no credit card | The Neat Company',
    description: 'Free trial with no credit card',
    canonical: `${APP_URL}/free-trial/`,
    twitter: {
      cardType: 'summary_large_image',
    },
    openGraph: {
      url: `${APP_URL}/free-trial/`,
      type: 'article',
      article: {
        modifiedTime: '2023-12-23T18:10:23+00:00',
      },
    },
    jsonLD: {
      webPage: {
        id: `${APP_URL}/free-trial/#webpage`,
        url: `${APP_URL}/free-trial/`,
        name: 'Free trial with no credit card | The Neat Company',
        isPartOf: { '@id': `${APP_URL}/#website` },
        datePublished: '2023-12-23T18:10:23+00:00',
        dateModified: '2023-12-23T18:10:23+00:00',
        description: 'Free trial with no credit card',
        breadcrumb: { '@id': `${APP_URL}/free-trial/#breadcrumb` },
        inLanguage: 'en-US',
        potentialAction: [{ '@type': 'ReadAction', target: [`${APP_URL}/free-trial/`] }],
        primaryImageOfPage: { '@id': 'https://www.neat.com/free-trial/#primaryimage' },
      },
      breadCrumb: {
        itemListElements: [
          { position: 1, name: 'Home', item: APP_URL },
          { position: 2, name: 'Free Trial' },
        ],
      },
    },
    uri: '/free-trial/',
    additionalMetaTags: [
      {
        property: 'article:publisher',
        content: 'https://www.facebook.com/NeatCompany/',
      },
      {
        name: 'twitter:label1',
        content: 'Est. reading time',
      },
      {
        name: 'twitter:data1',
        content: '11 minutes',
      },
    ],
  },
  sweetDeal: {
    title: 'Save $100 Off All Buy Now Plans',
  },
  trackReceiptsTrial: {
    title: 'Neat Receipt Scanner',
  },
}
